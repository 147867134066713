import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { WhatsappService } from './services/whatsapp.service';
import data from '../data';
import { environment } from 'src/environments/environment';
import { SeoService } from './services/seo.service';
import {TranslateService} from '@ngx-translate/core';

const uiDefaults = {
  navegacion: true,
  pie: true,
  whatsapp: false,
  buscador: false,
};

type UI = typeof uiDefaults;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  showNuevaSucursal = environment.production;
  esHorarioOficina = false;
  scrolled = false;
  ui: UI = { ...uiDefaults };
  whatsapp = data.contacto.whatsapp;

  @HostListener('window:scroll')
  handleScroll() {
    this.scrolled = this.document.scrollingElement.scrollTop > 500;
  }

  constructor(
    public router: Router,
    private whatsappService: WhatsappService,
    private activatedRoute: ActivatedRoute,
    private seoService: SeoService,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService
  ) {
    this.setLanguage();
  }

  private setLanguage() {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', 'es');
    }
    const language = localStorage.getItem('language');
    this.translate.setDefaultLang(language);

    ///SET REGION
    if (!localStorage.getItem('region')) {
      localStorage.setItem('region', 'es-MX');
    }
  }

  ngOnInit() {
    this.esHorarioOficina = this.whatsappService.esHorarioOficina;
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        map(e => this.activatedRoute),
        map((route) => {
          while (route.firstChild) { route = route.firstChild; }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe(route => {
        this.ui = { ...uiDefaults, ...route.ui };
      });
  }
}
