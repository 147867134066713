import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InicioRoutingModule } from './inicio-routing.module';
import { InicioComponent } from './inicio.component';
import { InicioRastreoComponent } from './inicio-rastreo/inicio-rastreo.component';
import { InicioBeneficiosComponent } from './inicio-beneficios/inicio-beneficios.component';
import { InicioCoberturaComponent } from './inicio-cobertura/inicio-cobertura.component';
import { InicioBannerComponent } from './inicio-banner/inicio-banner.component';
import { InicioServiciosComponent } from './inicio-servicios/inicio-servicios.component';
import { InicioContactoComponent } from './inicio-contacto/inicio-contacto.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {RecaptchaModule} from 'ng-recaptcha';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    InicioComponent,
    InicioBannerComponent,
    InicioRastreoComponent,
    InicioBeneficiosComponent,
    InicioServiciosComponent,
    InicioCoberturaComponent,
    InicioContactoComponent,
  ],
  imports: [
    InicioRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RecaptchaModule,
    TranslateModule,
  ]
})
export class InicioModule { }
