import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ResponseService} from './response.service';

export type Ubicacion = {
  id: string;
  estado: string;
  sucursales: Array<Sucursal>;
};

export interface Sucursal {
  calle: string;
  colonia: string;
  cp: string;
  descripcion: string;
  estado: string;
  id: string;
  lat: string;
  lon: string;
  numero: string;
  poblacion: string;
  prefijo: string;
  telefono: string;
  especial: boolean;
}

export interface Respuesta<T> {
  codigo: number;
  resultado: T;
}

@Injectable({
  providedIn: 'root',
})
export class CoberturaService {
  public ubicacionesUrl = `http://localhost:3008/Api/index.php/page`;

  constructor(public http: HttpClient) { }

  getUbicaciones() {

   // const body = { accion: 'page_mapa' };
    const body = new FormData();
    body.append('accion', 'page_mapa');
    const headers = new HttpHeaders()
      .append('Content-Type', 'multipart/form-data');

    const options = { headers };
    return this.http.post<ResponseService<Ubicacion[]>>(this.ubicacionesUrl + '/covertura', body)
      .toPromise();
  }

  buscarSucursales(termino: string, ubicaciones: Ubicacion[]): Ubicacion[] {
    const busqueda = termino.toLowerCase();
    const resultado = [];
    const coincide = (val: string) => val.toLowerCase().includes(busqueda);

    for (const ubicacion of ubicaciones) {
      const sucursales = [];

      for (const sucursal of ubicacion.sucursales) {
        if (coincide(sucursal.estado) || coincide(sucursal.descripcion)) {
          sucursales.push(sucursal);
        }
      }

      if (sucursales.length) {
        resultado.push({ ...ubicacion, sucursales });
      }
    }

    return resultado;
  }
}
