<video class="video" #video height="100%" autoplay muted loop playsinline>
  <source data-src="/assets/video/cobertura/web-640.webm" type="video/webm" />
  <source data-src="/assets/video/cobertura/web-640.mp4" type="video/mp4" />
</video>

<section class="contenido container my-5">
  <div class="row">
    <div class="col-lg-8 col-12 mx-auto text-center">
      <img src="/assets/images/cobertura/icon-80.png" aria-hidden="true"
        width="80"
        height="80">
      <div class="text-white text-center my-4">
        <h1 class="scale-font titulo">{{'cobertura.near_for_you' | translate}}</h1>
        <em class="scale-font hashtag">{{'main.hashtag' | translate}}</em>
      </div>
      <a class="d-inline-flex btn btn-verde shadow mx-auto"
        routerLink="/cobertura">
        {{'cobertura.see_coverage' | translate}}
      </a>
    </div>
  </div>
</section>
