<div class="modal fade " #compatibleModal tabindex="-1" role="dialog" aria-labelledby="compatibleTitle"
  aria-hidden="true" style="margin-top:10%">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Navegador no compatible</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="content">
          <h1 class="landing-title browsers-title"></h1>
          <div class="text-tip">Le recomendamos utilizar uno de los siguientes navegadores:</div>
          <div class="row" style="margin-top: 10px;padding-bottom: 3%;">
            <a class="col-6" href="http://www.google.com/chrome/" target="_blank" rel="noopener">
              <img src="assets/images/browser/logo-chrome.png" height="50" width="50" loading="lazy">
              <span style="margin-left: 10px;">Google Chrome</span>
            </a>
            <a class="col-6" href="http://www.firefox.com" target="_blank" rel="noopener">
              <img src="assets/images/browser/logo-firefox.png" height="50" width="50" loading="lazy">
              <span style="margin-left: 10px;">Mozilla Firefox</span>
            </a>
            <a class="col-6" href="http://www.opera.com" target="_blank" rel="noopener">
              <img src="assets/images/browser/logo-opera.png" height="50" width="50" loading="lazy">
              <span style="margin-left: 10px;">Opera</span>
            </a>
            <a class="col-6" href="https://support.apple.com/downloads#safari" target="_blank" rel="noopener">
              <img src="assets/images/browser/logo-safari.png" height="50" width="50" loading="lazy">
              <span style="margin-left: 10px;">Safari (macOS 10.8+)</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>