import { Component, OnInit, Input } from '@angular/core';
import data from 'src/data';

interface ResponsiveSource {
  media: string;
  src: string;
  type: string;
}

interface ResponsiveImage {
  url: string;
  sources: ResponsiveImageSource[];
}

interface ResponsiveImageSource {
  width: number;
  types: string[];
  portrait: boolean;
}

interface Servicio {
  id: number;
  titulo: string;
  descripcion: string;
  imagen: ResponsiveImage;
}

interface ServicioResponsive extends Servicio {
  sources: ResponsiveSource[];
}

function randomizar(items: unknown[], limite: number) {
  const a = [];
  const b = [...items];

  while (a.length < limite) {
    const i = Math.floor(Math.random() * b.length);
    const item = b.splice(i, 1)[0];
    a.push(item);
  }

  return a;
}

const pathInfoRegex = /^(?<path>.*)\/(?<filename>.*?)(?<ext>\..+)?$/;
function pathInfo(url: string): { path: string, filename: string, ext: string } {
  return (pathInfoRegex.exec(url)?.groups as any) ?? { path: '', filename: '', ext: '' };
}

function transformSources({ url, sources }: ResponsiveImage) {
  return sources
    .sort((a, b) => (a.width < b.width ? 1 : a.width > b.width ? -1 : 0))
    .reduce((sources, source, index, array) => {
      const media: string[] = [];
      const minWidth: number = array[index + 1] ? array[index + 1].width : 0;
      if (minWidth) {
        media.push(`(min-width: ${minWidth}px)`);
      }
      // media.push(`(orientation: landscape)`);

      const { width, types } = source;
      const { path, filename } = pathInfo(url);

      for (const type of types) {
        sources.push({
          src: `${path}/${filename}-${source.portrait ? 'port' : 'land'}@${width}.${type}`,
          media: media.join(' and '),
          type: `image/${type}`,
        });
      }

      return sources;
    }, [] as Array<ResponsiveSource>);
}

@Component({
  selector: 'app-inicio-servicios',
  templateUrl: './inicio-servicios.component.html',
  styleUrls: ['./inicio-servicios.component.scss'],
})
export class InicioServiciosComponent implements OnInit {
  private intervalo = 30;
  private timer: any = null;
  private servicios: ServicioResponsive[] = data.servicios.map((servicio) => ({ ...servicio, sources: transformSources(servicio.imagen) }));
  public lista: ServicioResponsive[] = [];

  @Input() limite: number = 0;

  ngOnInit() {
    clearInterval(this.timer);

    if (this.limite && this.servicios.length > this.limite) {
      const next = () => (this.lista = randomizar(this.servicios, this.limite));

      this.timer = setInterval(() => next(), this.intervalo * 1000);
      next();
    } else {
      this.lista = [...this.servicios];
    }
  }
}
