<section class="carousel slide carousel-fade"
  id="Inicio-banner-carousel"
  data-ride="carousel">
  <ol class="carousel-indicators">
    <li class="active" [ngClass]="{ active: i == 0 }"
      *ngFor="let frame of slides; let i = index"
      data-target="#Inicio-banner-carousel"
      [attr.data-slide-to]="i"></li>
  </ol>
  <div class="carousel-inner">
    <picture class="carousel-item" [ngClass]="{ active: i == 0 }"
      *ngFor="let frame of slides; let i = index">
      <source *ngFor="let source of frame.sources"
        srcset="{{source.src}}" media="{{source.media}}" type="{{source.type}}">
      <img class="carousel-image" alt="{{frame.titulo}}" loading="lazy" />
    </picture>

    <a class="carousel-control carousel-control-prev"
      href="#Inicio-banner-carousel" role="button" data-slide="prev">
      <fa-icon [icon]="icons.previous" size="3x" aria-hidden="true"></fa-icon>
      <span class="sr-only">Anterior</span>
    </a>

    <a class="carousel-control carousel-control-next"
      href="#Inicio-banner-carousel" role="button" data-slide="next">
      <fa-icon [icon]="icons.next" size="3x" aria-hidden="true"></fa-icon>
      <span class="sr-only">Siguiente</span>
    </a>
  </div>

  <button class="scroll-icon"
    type="button"
    (click)="bajar($event)">
    <span class="scroll-icon__dot"></span>
    <span class="sr-only">Más contenido</span>
  </button>
</section>
