import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Respuesta} from './inicio.service';
import {UtilitiesService} from './utilities.service';
import {ResponseService} from './response.service';
import {RastreoGuia} from '../models/rastreo.guia';
import {Ubicacion} from './cobertura.service';
import {Injectable} from '@angular/core';
import {Colonia} from '../models/colonia';
import {AvisoPrivacidad} from '../models/aviso.privacidad';
import {CotizacionResponse} from '../models/cotizacion.response';

@Injectable({
  providedIn: 'root'
})
export class MainServices {

  private baseUrl: string;
  constructor(private client: HttpClient, private utilitiesService: UtilitiesService) {
    this.baseUrl = environment.apiURL;
  }

  public getRastroGuia(input: string) {
    const body = { accion: 'rastreo', numerorastreo: input };
    return this.client.post<ResponseService<RastreoGuia>>(this.baseUrl + 'rastreo', body)
      .toPromise()
      .catch((error) => {
        throw error;
      });
  }

  public getCobertura() {
    const body = new FormData();
    body.append('accion', 'page_mapa');
    const headers = new HttpHeaders()
      .append('Content-Type', 'multipart/form-data');
    const options = { headers };
    return this.client.post<ResponseService<Ubicacion[]>>(this.baseUrl + 'covertura', body)
      .toPromise();
  }



  public consultFactura(folio: string) {
    const body = {
      folio_consult: folio
    };
    return this.client.post<ResponseService<{ uuid: string }>>(`${this.baseUrl}facturacion/consultar`, body).toPromise();
  }

  public consultInvoiceFiles(uuid: string, email: string = '', captchaResponse: string = '') {
    const body = {
      uuid,
      email,
      captcha_response: captchaResponse
    };
    return this.client.post<ResponseService<{file_name: string, file: string, mime_type: string}>>
    (`${this.baseUrl}facturacion/download`, body).toPromise();
  }

  public consultEstados() {
    return this.client.get<ResponseService<{ id: number, descripcion: string }[]>>(`${this.baseUrl}cotizador/estados`).toPromise();
  }
  public consultMunicipio(estado: number){
    return this.client.get<ResponseService<{id: number, descripcion: string, estado: number}[]>>(`${this.baseUrl}cotizador/municipios/${estado}`).toPromise();
  }
  public consultColonia(estado: string, municipio: string, colonia: string, codigoPostal: string = ''){
    const body = {
      estado,
      colonia,
      municipio,
      codigo_postal: codigoPostal
    };
    return this.client.post<ResponseService<
      Colonia[]>>
    (`${this.baseUrl}cotizador/colonias`, body).toPromise();
  }

  public consultCotizacion(data: any) {
    const body = data;
    return this.client.post<ResponseService<CotizacionResponse>>(`${this.baseUrl}cotizador/consult`, body).toPromise();
  }

  public consultAvisoPrivacidad() {
    return this.client.get<ResponseService<AvisoPrivacidad[]>>(`${this.baseUrl}aviso-privacidad`).toPromise();
  }

  public sendContactoInformation(name: string, email: string, comments: string, captchaResponse: string) {
    const body = {
      name,
      email,
      comments,
      captcha_response: captchaResponse
    };
    return this.client.post<ResponseService<any>>(`${this.baseUrl}send-contact`, body).toPromise();
  }

  public modificarContra(userName: string, currentPassword: string, newPassword: string) {
    const body = {
      userName,
      currentPassword,
      newPassword
    };
    return this.client.post<ResponseService<{ estatus: boolean }>>(`${this.baseUrl}facturacion/modificarContra`, body).toPromise();
  }
}
