<section class="container-fluid bg-azul-oscuro text-white">
  <div class="row">
    <div class="d-none d-md-block col-md-5 col-lg-6 p-0">
      <picture>
        <source srcset="assets/images/fondo_1@600.webp" type="image/webp">
        <source srcset="assets/images/fondo_1@600.jpg" type="image/jpg">
        <img class="image" width="600" height="600" aria-hidden="true" loading="lazy" />
      </picture>
    </div>

    <div class="contenido col-md-7 col-lg-6 mx-auto px-md-4 px-lg-5">
      <div class="my-5">
        <img class="d-block mx-auto"
          src="assets/images/icon-comentario.svg"
          width="60"
          height="60"
          aria-hidden="true"
          loading="lazy">

        <h1 class="h4 text-center my-4">
          {{'contacto.message' | translate}}
        </h1>
      </div>

      <form class="form-row my-5"
        [ngClass]="{ 'was-validated': submitted }"
        [formGroup]="form"
        novalidate
        (ngSubmit)="onSubmit()">
        <div class="form-group col-12 col-sm-6">
          <label for="inicio-contacto.nombre">
            {{'main.name' | translate}}
          </label>
          <div class="input-group"
            [ngClass]="{ 'is-invalid': submitted && form.controls.nombre.errors }">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <fa-icon [icon]="icons.user"></fa-icon>
              </span>
            </div>
            <input class="form-control" id="inicio-contacto.nombre"
              type="text"
              required
              formControlName="nombre">
          </div>
          <div class="invalid-feedback" *ngIf="isInvalid('nombre', 'required')">
            {{'validations.required' | translate}}
          </div>
        </div>

        <div class="form-group col-12 col-sm-6">
          <label for="inicio-contacto.email">
            {{'main.email' | translate}}
          </label>
          <div class="input-group"
            [ngClass]="{ 'is-invalid': submitted && form.controls.email.errors }">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <fa-icon [icon]="icons.email"></fa-icon>
              </span>
            </div>
            <input class="form-control" id="inicio-contacto.email"
              type="email"
              required
              formControlName="email">
          </div>
          <div class="invalid-feedback" *ngIf="isInvalid('email', 'required')">
            {{'validations.required' | translate}}
          </div>
        </div>

        <div class="form-group col-12">
          <label for="inicio-contacto.comentario">
            {{'contacto.comment' | translate}}
          </label>
          <div class="input-group"
            [ngClass]="{ 'is-invalid': submitted && form.controls.comentario.errors }">
            <div class="input-group-prepend mensaje">
              <span class="input-group-text">
                <fa-icon [icon]="icons.comment"></fa-icon>
              </span>
            </div>
            <textarea class="form-control" id="inicio-contacto.comentario"
              style="resize:none"
              rows="5"
              required
              formControlName="comentario"></textarea>
          </div>
          <div class="invalid-feedback" *ngIf="isInvalid('comentario', 'required')">
            {{'validations.required' | translate}}
          </div>
        </div>
        <div class="text-center" style="margin: 0 auto;">
          <input [ngModel]="captchaResponse"  formControlName="recaptchaReactive" type="hidden">
          <re-captcha
            #reCaptcha
            required
            class="g-recaptcha"
            [siteKey]="captchaPublicKey"
            (resolved)="onCompleteCaptcha($event)"></re-captcha>
        </div>

        <div class="col-12 mt-2"
          *ngIf="successalert">
          <div class="alert alert-success">
            <strong>{{'contacto.complete_send' | translate}}</strong>
          </div>
        </div>

        <div class="col-12 mt-3">
          <button class="btn btn-verde d-block m-o w-100" [disabled]="form.invalid"
            type="submit">
            {{'main.send' | translate}}
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
