import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { faCommentDots, faEnvelope, faUser } from 'src/app/icons/solid';
import { InicioService } from 'src/app/services/inicio.service';
import data from 'src/data';
import { map } from 'rxjs/operators';
import {MainServices} from '../../services/MainServices';
import {environment} from '../../../environments/environment';
import {RecaptchaComponent, RecaptchaFormsModule} from 'ng-recaptcha';

@Component({
  selector: 'app-inicio-contacto',
  templateUrl: './inicio-contacto.component.html',
  styleUrls: ['./inicio-contacto.component.scss'],
})
export class InicioContactoComponent implements OnInit {

  @ViewChild('reCaptcha') reCaptcha: RecaptchaComponent;
  public config = data.config;
  public submitted = false;
  public successalert = false;
  public form: FormGroup;
  public icons = {
    user: faUser,
    email: faEnvelope,
    comment: faCommentDots,
  };
  public captchaPublicKey = environment.captcha_public_key;
  public captchaResponse: string;

  constructor(
    private inicioService: InicioService,
    private spinnerService: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private service: MainServices,
  ) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      nombre: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(35)]],
      comentario: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      recaptchaReactive: new FormControl(null, Validators.required)
    });

    this.form.statusChanges.pipe(
      map((a) => console.log(a))
    );
  }

  public onCompleteCaptcha(response: string) {
    this.captchaResponse = response;

  }

  async onSubmit() {
    this.submitted = true;
    const {nombre, comentario, email} = this.form.value;
    if (this.form.invalid) { return; }
    this.spinnerService.show();
    this.service.sendContactoInformation(
      nombre,
      email,
      comentario,
      this.captchaResponse
    ).then(e => {
      if (e.data.send) {
        this.successalert = true;
      }
    }).finally(() => {
      this.spinnerService.hide();
      this.form.reset();
      this.reCaptcha.reset();
      setInterval(() => this.successalert = false, 3500);
      this.submitted = false;
    });
  }

  isInvalid(field: string, validation: string) {
    /*
    const invalid = this.form.controls[field].errors[validation];
    const touched = !this.form.controls[field].touched;
    return (this.submitted || touched) && invalid;*/
    return false;
  }
}
