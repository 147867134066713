import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DataTablesModule } from 'angular-datatables';
import { DatatableComponent } from './datatable/datatable.component';
import { NuevasucursalComponent } from './nuevasucursal/nuevasucursal.component';
import { WhatsappComponent } from './whatsapp/whatsapp.component';
import { SubirComponent } from './subir/subir.component';
import { CompatibleComponent } from './compatible/compatible.component';
import { DevelopmentInterceptor } from './services/development.interceptor';
import { InicioModule } from './inicio/inicio.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RecoleccionComponent } from './recoleccion/recoleccion.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import localePy from '@angular/common/locales/es-PY';
import localeEn from '@angular/common/locales/en';
import localeESMX from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ImageModalComponent } from './image-modal/image-modal.component';

registerLocaleData(localePy, 'es');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeESMX, 'es-MX');
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    NuevasucursalComponent,
    WhatsappComponent,
    SubirComponent,
    CompatibleComponent,
    DatatableComponent,
    ImageModalComponent,
  ],
  imports: [
    InicioModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    NgxSpinnerModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    DataTablesModule,
    FontAwesomeModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [ HttpClient ]
      }
    })
  ],
  providers: [
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: LOCALE_ID, useValue: !localStorage.getItem('region') ? 'es-MX' : localStorage.getItem('region')},
    { provide: HTTP_INTERCEPTORS, useClass: DevelopmentInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
