import { DOCUMENT } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input()
  public buscador: false;

  @ViewChild('navigationRef')
  private navigationRef: ElementRef<HTMLElement>;
  private scrollingElement: Element;
  private ticking = false;
  private breakpoint: number;


  set collapsed(collapsed: boolean) {
    this.navigationRef.nativeElement.classList.toggle('collapsed', collapsed);
  }

  @HostListener('window:scroll')
  handleScroll() {
    if (!this.ticking) {
      window.requestAnimationFrame(() => {
        this.collapsed = this.scrollingElement.scrollTop > this.breakpoint;
        this.ticking = false;
      });

      this.ticking = true;
    }
  }

  constructor(
    public router: Router,
    @Inject(DOCUMENT) document: Document,
  ) {
    this.scrollingElement = document.scrollingElement;
    this.breakpoint = document.body.clientHeight / 3;
  }

  ngOnInit() {

   }

  public openMenu(elementId: string = 'navbarNav') {
    const menu = document.getElementById(elementId);
    const classShow = menu.classList.value;
    if (classShow.includes('show')) {
      menu.classList.remove('show');
    } else {
      menu.classList.add('show');
    }
  }

}
