import { Component } from '@angular/core';

import { ScrollService } from '../services/scroll.service';
import { faAngleDoubleUp } from '../icons/solid';

@Component({
  selector: 'app-subir',
  templateUrl: './subir.component.html',
  styleUrls: ['./subir.component.scss'],
})
export class SubirComponent {
  public icon = faAngleDoubleUp
  constructor(private scrollService: ScrollService) {}

  subir(event: MouseEvent) {
    this.scrollService.scrollTo(0);
    event.preventDefault();

    return false;
  }
}
