import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilitiesService } from './utilities.service';

export interface Respuesta<T, C extends number = number> {
  codigo: C;
  resultado: T
}

export interface HomeResponse {
  carousel: HomeCarousel[];
  sitios: HomeSitio[];
  servicios: HomeServicio[];
  config: HomeConfig[];
}

export interface HomeCarousel {
  titulo: string;
  descripcion: string;
  url: string;
  sources: { width: number; types: string[]; portrait: boolean }[];
}

export interface HomeSitio {
  id: string;
  nombre: string;
  descripcion: string;
  link: string;
  imagen: string;
}

export interface HomeServicio {
  id: string;
  nombre: string;
  descripcion: string;
  imagen: string;
}

export interface HomeConfig {
  telefono: string;
  horario: string;
  facebook: string;
  correo: string;
  minbusqueda: string;
  maxbusqueda: string;
  placeholderbusqueda: string;
}

export interface ContactanosResponse {
  whatsapp: string
  telefono_whatsapp: string
  telefono: string
  horario: string
  facebook: string
  correo: string
}

export interface RastreoResponse { }

@Injectable({
  providedIn: 'root',
})
export class InicioService {
  public url = environment.apiURL;
  httpHeaders = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  });

  constructor(public http: HttpClient, private utilitiesService: UtilitiesService) { }

  getRastreo(num) {
    const options = { headers: this.httpHeaders };
    const body = { accion: 'rastreo', numerorastreo: num };

    return this.http.post<Respuesta<{}, 200> | Respuesta<string, 100 | 0>>(this.url + 'rastreo', body)
      .toPromise()
      .catch((error) => {
        this.utilitiesService.showNotification('error', 'Verifique su conexion a internet');
        throw error;
      });
  }

  sendEmail(datos) {
    const options = { headers: this.httpHeaders };
    const body = {
      accion: 'envio_correo',
      de: datos.email,
      nombre: datos.nombre,
      asunto: '',
      contenido: datos.comentario,
    };

    return this.http.post(this.url + 'enviocorreo.php', body, options)
      .toPromise()
      .catch((error) => {
        this.utilitiesService.showNotification('error', 'Verifique su conexion a internet');
        throw error;
      });
  }

  isCompatible() {
    return navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/) === -1;
  }
}
