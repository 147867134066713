import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WhatsappService } from '../services/whatsapp.service';
import { faWhatsapp } from '../icons/brands';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp.component.html',
  styleUrls: ['./whatsapp.component.scss'],
})
export class WhatsappComponent implements OnInit, OnChanges {
  @Input() phone: string = '';
  @Input() message: string = '';
  private baseUrl = 'https://api.whatsapp.com/send';
  public url = '';
  public icons = {
    whatsapp: faWhatsapp,
  };

  constructor(public whatsappService: WhatsappService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.phone || changes.message) {
      this.url = this.generateUrl(this.phone, this.message);
    }
  }

  private generateUrl(phone: string, text: string = '') {
    const url = new URL(this.baseUrl);
    url.searchParams.set('phone', phone);
    url.searchParams.set('text', text);//'Hola, ¿Podrían ayudarme?'

    return url.toString();
  }
}
