import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InicioService } from '../services/inicio.service';
import { Modal } from 'bootstrap';

@Component({
  selector: 'app-compatible',
  templateUrl: './compatible.component.html',
  styleUrls: ['./compatible.component.scss'],
})
export class CompatibleComponent implements OnInit, AfterViewInit {
  @ViewChild('compatibleModal') compatibleModal: ElementRef<HTMLElement>;

  constructor(private inicioService: InicioService) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.inicioService.isCompatible()) return;
    
    const modal = new Modal(this.compatibleModal.nativeElement);
    modal.show();
  }
}
