<form class="container needs-validation"
  [ngClass]="{ 'was-validated': submitted }"
  novalidate
  [formGroup]="rastreoForm"
  (ngSubmit)="onSubmitRastreo()">
  <div class="row" id="rastreo">
    <div class="col-12 col-md-10 col-lg-8 py-5 mx-auto">
      <h1 class="h1 mb-4 text-center">
        {{'rastreo.track_your_shipment' | translate}}
      </h1>
      <div class="input-group">
        <input class="form-control text-center"
          [ngClass]="{ 'is-invalid': submitted && this.rastreoForm.controls.numrastreo.errors }"
          type="text"
          formControlName="numrastreo"
          placeholder="{{'rastreo.num_guia_num_track' | translate}}"
          minlength="{{config.minbusqueda}}"
          maxlength="{{config.maxbusqueda}}"
          required
          data-toggle="tooltip"
          data-placement="top"
          aria-describedby="passwordHelpBlock">

        <div class="input-group-append">
          <button class="btn btn-md btn-success m-0 py-0"
            type="submit">
            {{'rastreo.track' | translate}}
          </button>
        </div>

        <div class="invalid-feedback"
          *ngIf="errores.required">
          {{'validations.num_track_req' | translate}}
        </div>

        <div class="invalid-feedback"
          *ngIf="errores.minlength || errores.maxlength">
          {{'validations.num_track' | translate}}
        </div>
      </div>

      <small class="form-text text-white-50">
        {{'rastreo.num_track_on_package' | translate}}
      </small>
    </div>
  </div>
</form>
