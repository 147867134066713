import {Component, ViewChild} from '@angular/core';

import data from '../../data';
import { faFacebook, faInstagram, faLinkedin } from '../icons/brands';
import {Tools} from '../tools';

const weekDayFormater = Intl.DateTimeFormat(!localStorage.getItem('region') ?  'es-mX' : localStorage.getItem('region'), { weekday: 'long', timeZone: 'UTC' });
function getWeekDay(weekday: number) {
  return weekDayFormater.format(new Date(`2017-01-0${weekday} 00:00:00`));
}

const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {

  @ViewChild('btnEnglish') btnEnglish;
  @ViewChild('btnSpanish') btnSpanish;

  public redesSociales = data.redesSociales;
  public correo = data.contacto.correo;
  public telefono = data.contacto.telefono;
  public icons = {
    facebook: faFacebook,
    instagram: faInstagram,
    linkedin: faLinkedin,
  };
  public horarios = data.horarios.map(({ inicio, fin }) => {
    const dias = getWeekDay(inicio.dia) + (inicio.dia === fin.dia ? '' : ' a ' + getWeekDay(fin.dia));
    const horario = `de ${inicio.hora}:00 a ${fin.hora}:00 hrs`;
    return capitalize(`${dias} ${horario}`);
  });

  public changeLanguage(lang: string) {
    if (lang === 'en') {
      Tools.setLanguagePage('en-US', 'en');
    } else {
      Tools.setLanguagePage('es-MX', 'es');
    }
  }

  public getCurrentLanguage(): string{
    const lang = localStorage.getItem('language');
    if (!lang || lang === 'es') { return 'es'; }
    else { return lang;}
  }

  constructor() {
  }

}
