<div class="container-md">
  <div class="row pt-5">
    <section class="col-12 col-sm-6  col-md-3 pb-5">
      <h1 class="titulo">{{'main.legality' | translate}}</h1>
      <ul>
        <li>
          <a class="link" routerLink="/ayuda/1">{{'main.notice_of_privacy' | translate}}</a>
        </li>
        <li>
          <a class="link" routerLink="/ayuda/2">{{'main.terms_and_conditions' | translate}}</a>
        </li>
        <li>
          <a class="link" routerLink="/login">Portal de pagos</a>
        </li>
      </ul>
    </section>

    <section class="col-12 col-sm-6 col-md-3 pb-5">
      <h1 class="titulo">{{'main.customer_support' | translate}}</h1>
      <ul>
        <li>
          <a class="link" href="{{'tel:' + correo}}">{{telefono}}</a>
        </li>
        <li>
          <span>{{'main.schedule_weekday_hours' | translate}}</span>
        </li>
        <li>
          <span>{{'main.schedule_saturday' | translate}}</span>
        </li>
        <li>
          <a class="link" href="{{'mailto:' + correo}}">{{correo}}</a>
        </li>
      </ul>
    </section>

    <section class="col-12 col-sm-6  col-md-3 pb-5">
      <h1 class="titulo">{{'main.follow' | translate}}</h1>
      <div>
        <a class="link social-media" *ngFor="let info of redesSociales"
          [href]="info.url"
          rel="opener"
          [title]="info.title">
          <fa-icon [icon]="icons[info.icon]" size="lg"></fa-icon>
          <span class="sr-only">{{info.title}}</span>
        </a>
      </div>
    </section>

    <section class="col-12 col-sm-6  col-md-3 pb-5">
      <h1 class="titulo">{{'main.language' | translate}}</h1>

      <table class="w-100">
        <tr>
          <td><a class="link" [className]="getCurrentLanguage() === 'es' ? 'link active' : 'link'" (click)="changeLanguage('es')">{{'languages.es' | translate}}</a></td>
          <td><a class="link" [className]="getCurrentLanguage() === 'en' ? 'link active' : 'link'" (click)="changeLanguage('en')">{{'languages.en' | translate}}</a></td>
        </tr>
      </table>


    </section>
    <section class="col-xl-12 mb-3 text-center">
      <span>Entregas Puntuales S de R.L de C.V. Todos los derechos reservados.</span>
    </section>
  </div>
</div>
