import { AfterViewInit, Component, ElementRef,  OnInit, ViewChild } from '@angular/core';
import { Modal } from 'bootstrap';
import {faBoxUsd, faEnvelope, faFileInvoiceDollar, faMoneyCheckAlt, faSuitcase, faTruck} from 'src/app/icons/light';
import data from 'src/data';

@Component({
  selector: 'app-inicio-beneficios',
  templateUrl: './inicio-beneficios.component.html',
  styleUrls: ['./inicio-beneficios.component.scss'],
})
export class InicioBeneficiosComponent implements OnInit, AfterViewInit {
  @ViewChild('elaboraGuiaModalRef')
  private elaboraGuiaModalRef: ElementRef<HTMLElement>;
  public elaboraGuiaModal: Modal;
  public elaboraGuia = data.sitios;
  public icons = {
    boxUsd: faBoxUsd,
    invoice: faFileInvoiceDollar,
    envelope: faEnvelope,
    suitcase: faSuitcase,
    money: faMoneyCheckAlt,
    truck: faTruck
  };

  ngOnInit() { }

  ngAfterViewInit() {
    this.elaboraGuiaModal = new Modal(this.elaboraGuiaModalRef.nativeElement);
  }
}
