import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { InicioService } from 'src/app/services/inicio.service';
import data from 'src/data';
import {MainServices} from '../../services/MainServices';
import {UtilitiesService} from '../../services/utilities.service';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-inicio-rastreo',
  templateUrl: './inicio-rastreo.component.html',
  styleUrls: ['./inicio-rastreo.component.scss']
})
export class InicioRastreoComponent implements OnInit {
  public config = data.config;
  public rastreoForm: FormGroup;
  public submitted = false;

  constructor(
    private router: Router,
    private inicioService: InicioService,
    private formBuilder: FormBuilder,
    private spinnerService: NgxSpinnerService,
    private services: MainServices,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.rastreoForm = this.formBuilder.group({
      numrastreo: ['', [
        Validators.required,
        Validators.minLength(this.config.minbusqueda),
        Validators.maxLength(this.config.maxbusqueda),
      ]],
    });
  }

  get errores(): { minlength?: boolean, maxlength?: boolean, required?: boolean; } {
    return this.submitted && this.rastreoForm.controls.numrastreo.errors || {} as any;
  }

  async onSubmitRastreo() {
    const utilitiesService = new UtilitiesService();
    this.submitted = true;
    this.spinnerService.show();
    if (!this.rastreoForm.valid) { return; }
    const numRastreo = this.rastreoForm.value.numrastreo;
    this.services.getRastroGuia(numRastreo).then((res) => {

      let error = '';

      if (res.data.cancelado === true) {
        Swal.fire(this.translate.instant('main.error'), this.translate.instant('rastreo.deliver_cancel'), 'error');
      } else {
        if (res.code === 200) {

          localStorage.setItem('config', JSON.stringify(this.config));
          localStorage.setItem('rastreo', JSON.stringify(res.data));
          localStorage.setItem('error', error);
          this.router.navigate(['/rastreo']);
        } else {
          utilitiesService.showNotification('error', res.msg);

          throw new Error(res.msg);
        }
      }

    }).finally(() => {
      this.spinnerService.hide();
    });
  }
}
