<section class="contenedor">
  <a class="beneficio"
    aria-label="Cotización de paquetería en línea"
    routerLink="/cotizador">
    <div class="contenido">
      <fa-icon class="icon" [icon]="icons.boxUsd" size="4x" aria-hidden="true"></fa-icon>
      <span class="h5 font-weight-bold text-uppercase">
        {{'main.quote_a_shipment' | translate}}
      </span>
    </div>
  </a>

  <a class="beneficio"
    aria-label="Facturación de paquetería en línea"
    routerLink="/facturacion">
    <div class="contenido">
      <fa-icon class="icon" [icon]="icons.invoice" size="4x" aria-hidden="true"></fa-icon>
      <span class="h5 font-weight-bold text-uppercase">
        {{'main.invoice_online' | translate}}
      </span>
    </div>
  </a>

  <button class="beneficio"
    aria-label="Elabora tu guía empresarial"
    (click)="elaboraGuiaModal.show()">
    <div class="contenido">
      <fa-icon class="icon" [icon]="icons.envelope" size="4x" aria-hidden="true"></fa-icon>
      <span class="h5 font-weight-bold text-uppercase">
        {{'main.create_deliver' | translate}}
      </span>
    </div>
  </button>

 <!-- <a class="beneficio"
     aria-label="Facturación de paquetería en línea"
     routerLink="/recolecciones">
    <div class="contenido">
      <fa-icon class="icon" [icon]="icons.truck" size="4x" aria-hidden="true"></fa-icon>
      <span class="h5 font-weight-bold text-uppercase">
        Consultar Recoleccion
      </span>
    </div>
  </a>-->
</section>

<div class="modal fade"
  #elaboraGuiaModalRef
  id="elaboraGuiaModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="elaboraGuiaModalTitle"
  aria-hidden="true">
  <section class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title" id="elaboraGuiaModalTitle">{{'main.select_access' | translate}}</div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="contenedor">
        <a class="beneficio" *ngFor="let sitio of elaboraGuia"
          [href]="sitio.link"
          [title]="sitio.descripcion"
          target="blank"
          rel="opener"
          (click)="elaboraGuiaModal.hide()">
          <div class="contenido">
            <fa-icon class="icon" [icon]="icons[sitio.icon]" size="4x"></fa-icon>
            <span class="h5 font-weight-bold text-uppercase">
              {{ sitio.titulo }}
            </span>
          </div>
        </a>
      </div>
    </div>
  </section>
</div>
