import { Component, ElementRef, OnInit } from '@angular/core';

import { HomeCarousel } from 'src/app/services/inicio.service';
import { ScrollService } from 'src/app/services/scroll.service';
import { faChevronLeft, faChevronRight } from 'src/app/icons/solid';
import data from 'src/data';

interface Slide {
  titulo: string | null;
  descripcion: string | null;
  sources: Array<Source>;
}

interface Source {
  media: string;
  src: string;
  type: string;
}

const pathInfoRegex = /^(?<path>.*)\/(?<filename>.*?)(?<ext>\..+)?$/;
function pathInfo(url: string): { path: string, filename: string, ext: string; } {
  return (pathInfoRegex.exec(url)?.groups as any) ?? { path: '', filename: '', ext: '' };
}

function transformSources({ url, sources }: { url: string, sources: HomeCarousel['sources']; }) {
  return sources
    .sort((a, b) => (a.width < b.width ? 1 : a.width > b.width ? -1 : 0))
    .reduce((sources, source, index, array) => {
      const media = [];
      const minWidth = array[index + 1] ? array[index + 1].width : 0;
      if (minWidth) {
        media.push(`(min-width: ${minWidth}px)`);
      }

      media.push(`(orientation: ${source.portrait ? 'portrait' : 'landscape'})`);

      const { width, types } = source;
      const { path, filename } = pathInfo(url);

      for (const type of types) {
        sources.push({
          src: `${path}/${filename}-${source.portrait ? 'port' : 'land'}@${width}.${type}`,
          media: media.join(' and '),
          type: `image/${type}`,
        });
      }

      return sources;
    }, [] as Array<Source>);
}

function transformSlides(slides: Array<HomeCarousel>) {
  return slides.map((slide) => ({
    titulo: slide.titulo,
    descripcion: slide.descripcion,
    sources: transformSources(slide),
  }));
}

@Component({
  selector: 'app-inicio-banner',
  templateUrl: './inicio-banner.component.html',
  styleUrls: ['./inicio-banner.component.scss'],
})
export class InicioBannerComponent implements OnInit {
  public slides: Array<Slide> = transformSlides(data.banner);
  public icons = {
    previous: faChevronLeft,
    next: faChevronRight,
  };

  constructor(
    private elRef: ElementRef<HTMLElement>,
    private scrollService: ScrollService
  ) { }

  ngOnInit() { }

  bajar(event: MouseEvent) {
    const element = this.elRef.nativeElement;
    this.scrollService.scrollTo(element.offsetHeight + element.offsetTop - 62);
    event.preventDefault();

    return false;
  }
}
