import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  scrollTo(top: number) {
    this.document.scrollingElement.scrollTo({ top, behavior: 'smooth' });
  }
}
