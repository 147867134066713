import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { filter, map } from "rxjs/operators";
import { Observable } from 'rxjs';
import { CoberturaService } from "./cobertura.service";

type Response<T> = { codigo: number; resultado: T; };

function transformCoberturaRespose(data: Response<{ [k in string]: { [k in string]: string }[] }[]>) {
  const respuesta = { codigo: data.codigo, resultado: [] };

  if (Array.isArray(data.resultado)) {
    for (const [estado, sucursales] of Object.entries(data.resultado[0])) {
      respuesta.resultado.push({
        id: estado.replace(/ /g, '-'),
        estado,
        sucursales: sucursales
          .map((sucursal) => ({
            ...sucursal,
            especial: +sucursal.id === 49 || +sucursal.id === 900,
          } as any))
          .sort((a, b) => a.descripcion > b.descripcion ? +1 : (a.descripcion < b.descripcion ? -1 : 0)),
      });
    }
  }
  return respuesta;
}

@Injectable({
  providedIn: 'root',
})
export class DevelopmentInterceptor implements HttpInterceptor {
  constructor(private coberturaService: CoberturaService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    switch (request.url) {
      case this.coberturaService.ubicacionesUrl:
        return next.handle(request)
          .pipe(
            filter((event: HttpEvent<any>): event is HttpResponse<any> => (event instanceof HttpResponse)),
            map((event) => event.clone({ body: transformCoberturaRespose(event.body) }))
          );

      default:
        return next.handle(request);
    }
  }
}
