<div class="bg-azul-oscuro px-3 py-5">
  <h1 class="h1 text-center m-0 mt-5">
    {{'main.logistics_solutions' | translate}}
  </h1>
</div>

<div class="servicios bg-azul">
  <article class="servicio"
    *ngFor="let servicio of lista">
    <div class="imagen">
      <picture>
        <source *ngFor="let source of servicio.sources"
          srcset="{{source.src}}" media="{{source.media}}" type="{{source.type}}">
        <img alt="{{servicio.titulo}}" width="320" height="320" loading="lazy" />
      </picture>
    </div>
    <div class="informacion">
      <h2 class="h2 mb-3">{{ servicio.titulo | translate }}</h2>
      <p class="descripcion">{{ servicio.descripcion | translate}}</p>
    </div>
  </article>
</div>
