import { google } from '@agm/core/services/google-maps-types';

export const environment = {
  production: true,
  apiURLPage: `https://api.pmm.com.mx/page_pmm/`,
  apiURL: 'https://sgo.pmm.com.mx/Api/index.php/page/',
  googleMaps: {
    apiKey: 'AIzaSyCPwYaYD92Zogl546a8l_saU_btdu02Qz8'
  },
  captcha_public_key: '6LfHf0AgAAAAABxPwDECh2-FPtuVqhKdhvFXPnmK',
};
