import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {
  private static fecha = new Date();

  constructor() { }

  public get esHorarioOficina() {
    if (!environment.production) return true;

    const dia = WhatsappService.fecha.getDay();
    const hora = WhatsappService.fecha.getHours();
    const diaHabil = dia > 0 && dia < 6;
    const horaHabil = hora >= 8 && hora <= 18;

    return diaHabil && horaHabil;
  };
}
