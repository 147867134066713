import {NgModule} from '@angular/core';
import {Routes, RouterModule, Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {RecoleccionModule} from './recoleccion/recoleccion.module';
import {Title, Meta} from '@angular/platform-browser';

export const routes: Routes = [

  {
    path: 'cotizador',
    loadChildren: () => import('./cotizador/cotizador.module').then(m => m.CotizadorModule),
    data: {
      seo: {
        title: 'Cotiza tu envio', metaTags: [
          {name: 'description', content: 'Cotiza con nosotros tu envio, facil y rapido',},
          {property: 'og:title', content: 'Cotiza tu envio'},
          {proprety: 'og:description', content: 'Cotiza con nosotros tu envio, facil y rapido'},
          {name: 'twitter:card', content: 'summary_large_image'},
        ]
      }
    }
  }, {
    path: 'cobertura',
    loadChildren: () => import('./cobertura/cobertura.module').then(m => m.CoberturaModule),
    data: {ui: {pie: false}}
  }, {
    path: 'ayuda/:tipo',
    loadChildren: () => import('./ayuda/ayuda.module').then(m => m.AyudaModule),
    data: {}
  }, {
    path: 'rastreo',
    loadChildren: () => import('./rastreo/rastreo.module').then(m => m.RastreoModule),
    data: {ui: {buscador: true}, seo: {
      title: 'Rastrea tu envio',
        metaTags: [
          {name: 'description', content: 'Rastrea tu envío de forma rapida y verificar quien recibió tu paquete y donde'},
          {name: 'og:title', content: 'Rastrea tu Envio'},
          {property: 'og:description', content: 'Rastrea tu envío de forma rapida y verificar quien recibió tu paquete y donde'},
          {name: 'twitter:card', content: 'summary_large_image'},
        ]
      }}
  },
  {
    path: 'rastreo/:id',
    loadChildren: () => import('./rastreo/rastreo.module').then(m => m.RastreoModule),
    data: {ui: {buscador: true}, seo: {
        title: 'Rastrea tu envio',
        metaTags: [
          {name: 'description', content: 'Rastrea tu envío de forma rápida y verificar quien recibió tu paquete y donde'},
          {name: 'og:title', content: 'Rastrea tu Envio'},
          {property: 'og:description', content: 'Rastrea tu envío de forma rápida y verificar quien recibió tu paquete y donde'},
          {name: 'twitter:card', content: 'summary_large_image'},
        ]
      }}
  }, {
    path: 'facturacion',
    loadChildren: () => import('./facturacion/facturacion.module').then(m => m.FacturacionModule),
    data: {
      seo: {
        title: 'Facturacion en Linea', metaTags: [
          {name: 'description', content: 'Realiza la facturacion de tu envio o recupera tu factura',},
          {property: 'og:title', content: 'Facturacion en Linea'},
          {proprety: 'og:description', content: 'Realiza la facturacion de tu envio o recupera tu factura'},
          {name: 'twitter:card', content: 'summary_large_image'},
        ]
      }
    }
  }, {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    data: {ui: {navegacion: false, pie: false}}
  }, {
    path: 'portalpagos',
    loadChildren: () => import('./portalpagos/portalpagos.module').then(m => m.PortalpagosModule),
    data: {ui: {pie: false}}
  },
  {
    path: 'recoleccion',
    loadChildren: () => import('./recoleccion/recoleccion.module').then(m => m.RecoleccionModule),
    data: {ui: {navegacion: true, pie: true}}
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
