import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './inicio.component';
import { InicioServiciosComponent } from './inicio-servicios/inicio-servicios.component';

const routes: Routes = [{
  path: '',
  pathMatch: 'full',
  component: InicioComponent,
  data: {
    ui: { whatsapp: true }
  },
},
{
  path: 'servicios',
  component: InicioServiciosComponent,
  data: {
    ui: { whatsapp: true }
  }
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InicioRoutingModule { }
