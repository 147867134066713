export class Tools {
  public static uuid(): string {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  public static hideBoostrapModal(idModel: string) {

  }

  public static onlyDecimalNumberKey(event, acceptDecimal: boolean = false) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (acceptDecimal) {
      return !(charCode != 46 && charCode > 31
        && (charCode < 48 || charCode > 57));
    } else {
      return (charCode < 48 || charCode > 57);
    }
  }

  public static formatNumber(entry: string): string {
    const match = entry
      .replace(/\D+/g, '').replace(/^1/, '')
      .match(/([^\d]*\d[^\d]*){1,10}$/)[0]
    const part1 = match.length > 2 ? `(${match.substring(0,3)})` : match;
    const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : '';
    const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : '';
    return `${part1}${part2}${part3}`;
  }

  public static setLanguagePage(region: string, lang: string) {
    localStorage.setItem('region', region);
    localStorage.setItem('language', lang);
    location.reload();
  }

}
