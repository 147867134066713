import { AfterViewInit, ViewChild } from '@angular/core';
import { Component, ElementRef } from '@angular/core';

const round = (n: number) => Math.round(n * 1000) / 1000;
function fontSizeRamp(min: number, max: number, minVW: number = 480, maxVW: number = 960) {
  const minWidth = minVW / 16;
  const maxWidth = maxVW / 16;
  const slope = (max - min) / (maxWidth - minWidth);
  const yAxisIntersection = -minWidth * slope + min;
  return `clamp(${min}rem, ${round(yAxisIntersection)}rem + ${round(slope * 100)}vw, ${max}rem)`;
}

@Component({
  selector: 'app-inicio-cobertura',
  templateUrl: './inicio-cobertura.component.html',
  styleUrls: ['./inicio-cobertura.component.scss'],
})
export class InicioCoberturaComponent implements AfterViewInit {
  @ViewChild('video')
  video: ElementRef<HTMLVideoElement>;

  ngAfterViewInit() {
    this.lazyloadVideo();
  }

  lazyloadVideo() {
    if (!('IntersectionObserver' in window)) return;

    const observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (!entry.isIntersecting) return;

        const video = entry.target as HTMLVideoElement;
        const sources = Array.from(video.querySelectorAll('source'));
        for (const source of sources) {
          source.src = source.dataset.src;
        }

        video.load();
        observer.unobserve(video);
      }
    });

    observer.observe(this.video.nativeElement);
  }
}
